.tips-container {
  margin: 5%;
  padding: 30px;
}

.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.header-icon {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.header-container h2 {
  font-size: 24px;
  margin: 0;
}

.papers-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  width: 90%;
}

.preview-paper {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
}

.preview-paper:hover {
  transform: translateY(-4px);
}

.picture {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.text-container {
  padding: 20px;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  margin-bottom: 10px;
}

.view-button {
  width: 100%;
}
