.paper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
}

.paper-title {
  font-size: 80px;
  margin-bottom: 20px;
  font-family: 'Nunito Sans, sans-serif';
}

.pdf-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.pdf-page-container {
  margin-bottom: 20px;
}

.pdf-page {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-counter {
  font-size: 16px;
}

@media (max-width: 768px) {
  .pdf-page {
    width: 100%;
  }

  .paper-title {
    font-size: 20px;
  }

  .page-counter {
    font-size: 14px;
  }
}
