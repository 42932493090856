.banner {
  position: relative;
  width: 100%;
  height: 25vh; /* Ajusta la altura al 25% de la vista */
  max-height: 300px; /* Limita la altura para evitar que crezca demasiado en pantallas grandes */
  overflow: hidden;
  margin-bottom: 10px; /* Añadir un pequeño margen inferior */
}

.banner__container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.slide {
  display: none; /* Ocultar todas las diapositivas por defecto */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slide.active {
  display: block; /* Mostrar solo la diapositiva activa */
}

.banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen para que cubra todo el banner sin distorsión */
}

.banner__dots {
  position: absolute;
  bottom: 5px; /* Ajusta la posición desde la parte inferior */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 3px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

/* Responsividad */
@media (max-width: 768px) {
  .banner {
    height: 20vh; /* Disminuye la altura en dispositivos medianos */
  }
}

@media (max-width: 480px) {
  .banner {
    height: 10vh; /* Disminuye aún más en dispositivos móviles */
  }

  .banner__dots {
    bottom: 2px; /* Ajusta la posición de los puntos */
  }

  .dot {
    height: 6px;
    width: 6px;
  }
}
